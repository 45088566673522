import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "../component/ui/Header";
import Admin from "./Admin";
import Directory from "./Directory";
import Home from "./Home";
import Privacy from "./Privacy";
import HealeesRecordsPrivacy from "./ HealeesRecordsPrivacy";
import HealeesRecordsPrivacy2 from "./ HealeesRecordsPrivacy2";

const HomeRoute = () => {
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Directory} />
          <Route exact path="/old" component={Home} />
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/healers_records_privacy" component={HealeesRecordsPrivacy} />
          <Route exact path="/healers_record_privacy" component={HealeesRecordsPrivacy2} />
          <Route path="/privacy_for_healers_app" component={HealeesRecordsPrivacy2} />
          <Route path="/privacy_for_healers_app/*" component={HealeesRecordsPrivacy2} />
          {/* <Route exact path="/about" component={About} />
          <Route exact path="/directory" component={Directory} /> */}
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default HomeRoute;
