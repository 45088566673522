import React from "react";
import { Healer } from "../../model/healer";
export type HealersCardDataType = {
  healer: Healer;
};

const HealersCard = ({ healer }: HealersCardDataType) => {
  return (
    <div>
      <div>omm</div>
      <div>{healer.name}</div>
      <div>{healer.city}</div>
      <div>{healer.district}</div>
      <div>{healer.state}</div>
      <div>{healer.phoneNo}</div>
      <div>{healer.id}</div>
    </div>
  );
};

export default HealersCard;
