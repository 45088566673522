import React from "react";
import SearchModal from "../../components/SearchModal";
import Input from "./Input";
import DirectoryService from "../../service/directoryService";
import Loading from "./loading";
enum FilterType {
  STATE,
  DISTRICT,
  CITY,
}
export type LocationSelectorProps = {
  onLocationLocked;
};
const LocationSelector = ({ onLocationLocked }: LocationSelectorProps) => {
  const [stateList, setStateList] = React.useState<string[]>();
  const [districtList, setdistrictList] = React.useState<string[]>();
  const [searchModalList, setSearchModalList] = React.useState<string[]>();
  const [filterType, setFilterType] = React.useState<FilterType>();

  const [selectedState, setSelectedState] = React.useState<string>("");
  const [selectedDistrict, setSelectedDistrict] = React.useState<string>("");
  const [selectedCity, setSelectedCity] = React.useState<string>("");

  const [statesLoading, setStatesLoading] = React.useState<boolean>(false);
  const [districtLoading, setDistrictLoading] = React.useState<boolean>(false);
  const [citiesLoading, setCitiesLoading] = React.useState<boolean>(false);

  const [disableStates, setDisableStates] = React.useState<boolean>(false);
  const [disableDistrict, setDisableDistrict] = React.useState<boolean>(true);
  const [disableCities, setDisableCities] = React.useState<boolean>(true);

  const [label, setLabel] = React.useState<string>("Search");

  React.useEffect(() => {
    (async () => {
      setStatesLoading(true);
      setDistrictLoading(true);
      setCitiesLoading(true);
      const states = await DirectoryService.getStates();
      const statesList = Object.values(states) as string[];
      setStateList(statesList);
      setSelectedDistrict("");
      setSelectedCity("");
      setStatesLoading(false);
      setDistrictLoading(false);
      setCitiesLoading(false);
    })();
  }, []);

  const [searchModalOpen, setSearchModalOpen] = React.useState(false);
  // const searchInput = React.useRef(null);
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800 text-2xl">
          Select the location
        </h2>
      </header>

      {statesLoading ? (
        <Loading />
      ) : (
        <Input
          label={"States:"}
          placeholder={"Select the State"}
          value={selectedState ? selectedState : ""}
          showIcon={true}
          onClick={(e) => {
            setLabel("States");
            setDisableDistrict(true);
            setDisableCities(true);
            setFilterType(FilterType.STATE);
            setSearchModalList(stateList);
            e.stopPropagation();
            setSearchModalOpen(true);
            setSelectedDistrict("");
            setSelectedCity("");
          }}
        />
      )}

      {districtLoading ? (
        <Loading />
      ) : (
        <Input
          label={"Districts:"}
          showIcon={true}
          placeholder="Select the District"
          value={selectedDistrict ? selectedDistrict : ""}
          disabled={disableDistrict}
          onClick={(e) => {
            setLabel("Districts");
            setFilterType(FilterType.DISTRICT);
            setSearchModalList(districtList);
            e.stopPropagation();
            setSelectedCity("");
            setSearchModalOpen(true);
          }}
        />
      )}

      {citiesLoading ? (
        <Loading />
      ) : (
        <Input
          disabled={disableCities}
          showIcon={true}
          label={"Cities:"}
          placeholder="Select the City"
          value={selectedCity}
          // disabled={true}
          onClick={(e) => {
            setLabel("Cities");
            setFilterType(FilterType.CITY);
            e.stopPropagation();
            setSearchModalOpen(true);
          }}
        />
      )}
      <SearchModal
        id="search-modal"
        searchId="search"
        placeholder="Search"
        modalOpen={searchModalOpen}
        setModalOpen={setSearchModalOpen}
        list={searchModalList}
        label={label}
        onOptionSelect={async (option) => {
          if (FilterType.STATE == filterType) {
            setDisableDistrict(false);
            setDisableCities(true);
            setSelectedState(option);
            setDistrictLoading(true);
            setCitiesLoading(true);
            const districtsList = await DirectoryService.getDistricts(
              option,
              "",
            );
            const districtList = Object.values(districtsList) as string[];
            setSearchModalList(districtList);
            setDistrictLoading(false);
            setCitiesLoading(false);
            setdistrictList(districtList);
          } else if (FilterType.DISTRICT == filterType) {
            setDisableCities(false);
            setSelectedDistrict(option);
            setCitiesLoading(true);
            const citiesList = await DirectoryService.getCities(option, "");
            const cityList = Object.values(citiesList) as string[];
            setSearchModalList(cityList);
            setCitiesLoading(false);
          } else if (FilterType.CITY == filterType) {
            setSelectedCity(option);
            onLocationLocked(selectedState, selectedDistrict, option);
          }
        }}
      />
    </div>
  );
};

export default LocationSelector;
