import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Healers } from "../../model/healers";
import DirectoryService from "../../service/directoryService";
import HealerDefaultView from "./HealerDefaultView";
import Loading from "./loading";
import { locationType } from "./MainArea";
const copyIcon = require("../../assets/img/copy.svg");
import { toast } from "react-toastify";

export type HealersDirectoryProps = {
  location: locationType;
};
const HealersDirectory = ({ location }: HealersDirectoryProps) => {
  const [isLocationSelected, setIsLocationSelected] = React.useState(false);
  const [healersList, setHealersList] = React.useState<Healers>(null);
  const [loading, setLoading] = React.useState<boolean>(false);

  const mergeHealers = (healersList) => {
    let txt = "";
    Object.values(healersList).forEach((healer) => {
      const healerName = healer.name;
      const healerPhone = healer.phoneNo;
      txt += healerName + " : " + healerPhone + ", ";
    });

    return txt;
  };
  React.useEffect(() => {
    if (location && location.city && location.district && location.state) {
      setIsLocationSelected(true);
      setLoading(true);
      (async () => {
        const healersLists = await DirectoryService.getHealers(
          location.city,
          location.district,
          location.state,
        );
        setHealersList(healersLists);
        setLoading(false);
      })();
    }
  }, [location]);
  return (
    <div className="flex flex-col col-span-full sm:col-span-6 bg-white shadow-lg rounded-sm border border-slate-200">
      <header className="px-5 py-4 border-b border-slate-100">
        <h2 className="font-semibold text-slate-800 text-2xl">Acuhealer</h2>
      </header>
      {isLocationSelected ? (
        <div className="p-3">
          <div className="overflow-x-auto">
            {loading ? (
              <Loading />
            ) : (
              <table className="table-auto w-full">
                {/* Table header */}
                <thead className="text-sm uppercase text-slate-400 bg-slate-50 rounded-sm">
                  <tr>
                    <th className="p-2">
                      <div className="font-semibold text-left">Name</div>
                    </th>
                    <th className="p-2">
                      <div className="flex flex-row">
                        <div className="font-semibold text-center">
                          Phone Number
                        </div>
                        <div className="mx-2 justify-center items-center">
                          <CopyToClipboard
                            text={mergeHealers(healersList)}
                            onCopy={() => {
                              toast.success("All healer copied sucessfully", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                              });
                            }}
                          >
                            <img
                              className="w-6 h-6 rounded-none shadow-lg"
                              src={copyIcon.default}
                              alt="translation"
                            />
                          </CopyToClipboard>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* Table body */}
                <tbody className="text-base font-medium divide-y divide-slate-100">
                  {healersList ? (
                    Object.values(healersList).map((healer, index) => {
                      return (
                        <tr key={index}>
                          <td className="p-2">
                            <div className="flex items-center">
                              <svg
                                className="shrink-0 mr-2 sm:mr-3"
                                width="36"
                                height="36"
                                viewBox="0 0 36 36"
                                fill="#6366f1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M18,19v-2c0.45-0.223,1.737-1.755,1.872-2.952c0.354-0.027,0.91-0.352,1.074-1.635c0.088-0.689-0.262-1.076-0.474-1.198	c0,0,0.528-1.003,0.528-2.214c0-2.428-0.953-4.5-3-4.5c0,0-0.711-1.5-3-1.5c-4.242,0-6,2.721-6,6c0,1.104,0.528,2.214,0.528,2.214	c-0.212,0.122-0.562,0.51-0.474,1.198c0.164,1.283,0.72,1.608,1.074,1.635C10.263,15.245,11.55,16.777,12,17v2c-1,3-9,1-9,8h24	C27,20,19,22,18,19z" />
                              </svg>

                              <div className="text-slate-800">
                                {healer.name}
                              </div>
                            </div>
                          </td>
                          <td className="p-2 flex flex-row">
                            <div className="text-center">{healer.phoneNo}</div>
                            <div className="mx-2 justify-center items-center">
                              <CopyToClipboard
                                text={`${healer.name} : ${healer.phoneNo}`}
                                onCopy={() => {
                                  toast.success("Copied sucessfully", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                  });
                                }}
                              >
                                <img
                                  className="w-6 h-6 rounded-none shadow-lg"
                                  src={copyIcon.default}
                                  alt="translation"
                                />
                              </CopyToClipboard>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p>No entries found</p>
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      ) : (
        <HealerDefaultView />
      )}
    </div>
  );
};

export default HealersDirectory;
