import { async } from "@firebase/util";
import React from "react";
import Input from "../component/ui/Input";
import Loading from "../component/ui/loading";
import WelcomeBanner from "../component/ui/WelcomeBanner";
import DirectoryService from "../service/directoryService";

type logs = {
  percentage: number;
  logs: string[];
  status: string;
};

const Admin = () => {
  const [file, setFile] = React.useState();
  const [fileName, setFileName] = React.useState();
  const [status, setSetStatus] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [token, setToken] = React.useState("");

  const [percentage, setPercentage] = React.useState<number>(0);
  const [logs, setLogs] = React.useState<string[]>([]);
  const [jstatus, setJStatus] = React.useState<string>(null);
  const [lastUpdatedBy, setLastUpdatedBy] = React.useState<string>(null);
  const [time, setTime] = React.useState<string>(null);

  const [showUplaod, setShowUpload] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    (async () => {
      if (token) {
        await refreshLogs();
      }
    })();
  }, []);

  const refreshLogs = async () => {
    const _t = await DirectoryService.getLogs(token);
    setLogs(_t.logs);
    setPercentage(_t.percentage);
    setJStatus(_t.status);
    setTime(_t.time);
    setLastUpdatedBy(_t.lastUpdatedBy);

    /***
     * LastUpdatedBy
     * Time
     */
  };

  const saveFile = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setShowUpload(true);
  };
  const onUploadFile = async () => {
    setSetStatus("Processing...Do not refresh the page");
    await DirectoryService.uploadTemplate(fileName, file, token);
    setSetStatus("Job is started... Check Job status");
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      <WelcomeBanner message={"Welcome to admin pannel"} />

      <div className="flex flex-col space-y-8">
        <div className="flex flex-col space-y-4">
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <Input
              label={"Email"}
              placeholder={"Enter the email"}
              onChange={(e) => {
                setEmail(e);
              }}
              value=""
              name="email"
              showIcon={false}
              onClick={() => {}}
            />
            <Input
              label={"Password"}
              showIcon={false}
              placeholder={"Enter the password"}
              value=""
              name="password"
              iType={"password"}
              onChange={(e) => {
                setPassword(e);
              }}
              onClick={() => {}}
            />
            {/* <input
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={async () => {
                const t = await DirectoryService.getToken(email, password);
                setToken(t);
              }}
            >
              <span>Login</span>
            </input> */}

            <input
              name="Login"
              value="Login"
              type="submit"
              onClick={async () => {
                setLoading(true);
                const t = await DirectoryService.getToken(email, password);
                setToken(t);
                setLoading(false);
              }}
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            />
            {loading ? <Loading /> : null}
          </form>
        </div>
        {token && (
          <div className="flex flex-col space-y-4">
            <div className="flex flex-col space-y-4">
              <p className="font-bold">Last uploaded template</p>
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={async () => {
                  await DirectoryService.downloadTemplate(token);
                }}
              >
                <span className="ml-2">Download the template</span>
              </button>
            </div>

            <div className="flex flex-col space-y-4">
              <p className="font-bold">Upload new template</p>
              <p className="font-light">
                Select the file first, then click upload button (File must ends
                with .xlsx)
              </p>
              <input
                className="btn"
                type="file"
                accept=".xlsx"
                onChange={saveFile}
              />
              {showUplaod && (
                <input
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  type="button"
                  value="Upload"
                  onClick={async () => {
                    onUploadFile();
                  }}
                />
              )}
              <p className="font-medium">{status}</p>
            </div>

            <div className="flex flex-col space-y-4">
              <button
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={async () => {
                  await refreshLogs();
                }}
              >
                Refresh the Job status
              </button>
            </div>

            <div>
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-hidden">
                      <table className="min-w-full border text-center bg-white">
                        <thead className="border-b">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 border-r"
                            >
                              Value
                            </th>
                          </tr>
                        </thead>

                        {percentage != 0 && (
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                                Percentage
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                {percentage}
                              </td>
                            </tr>
                          </tbody>
                        )}

                        {logs.length != 0 && (
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                                Logs
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                <div>
                                  <ul>
                                    {logs.map((log, index) => {
                                      return <li key={index}>{log}</li>;
                                    })}
                                  </ul>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        )}

                        {/* {jstatus != null && (
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                                Status
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                {jstatus}
                              </td>
                            </tr>
                          </tbody>
                        )} */}

                        {jstatus != null && (
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                                Status
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                {jstatus}
                              </td>
                            </tr>
                          </tbody>
                        )}

                        {lastUpdatedBy != null && (
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                                Last UpdatedBy
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                {lastUpdatedBy}
                              </td>
                            </tr>
                          </tbody>
                        )}

                        {time != null && (
                          <tbody>
                            <tr className="bg-white border-b">
                              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                                Time taken to Update
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                                {time}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <p className="font-light">
                Click the refresh button to get fresh logs
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
