import React from "react";
import HealersDirectory from "./HealerDirectory";
import LocationSelector from "./LocationSelector";
import WelcomeBanner from "./WelcomeBanner";

export type locationType = {
  state: string;
  district: string;
  city: string;
};
const MainArea = () => {
  const [location, setLocation] = React.useState<locationType>(null);
  const onLocationLocked = (
    $state: string,
    $district: string,
    $city: string,
  ) => {
    setLocation({ state: $state, district: $district, city: $city });
  };
  return (
    <main>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <WelcomeBanner
          message={
            "Please choose States, Districts and Cities to find Acuhealer details"
          }
        />

        <div className="grid grid-cols-12 gap-6">
          <LocationSelector onLocationLocked={onLocationLocked} />
          <HealersDirectory location={location} />
        </div>
      </div>
    </main>
  );
};

export default MainArea;
