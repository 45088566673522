import React from "react";
export type InputProps = {
  placeholder?: string;
  onClick?: any;
  onChange?: any;
  value?: string;
  label?: string;
  disabled?: boolean;
  showIcon?: true | false;
  iType?: "search" | "password";
  name?: string;
};

const Input = ({
  name,
  placeholder,
  onClick,
  value,
  label,
  disabled,
  showIcon,
  onChange,
  iType = "search",
}: InputProps) => {
  const [$value, setValue] = React.useState<string>(value);
  const [$disabled, setDisabled] = React.useState<boolean>(disabled);

  React.useEffect(() => {
    setValue(value);
    setDisabled(disabled);
  }, [value, disabled]);
  return (
    <div
      className={`m-4 flex flex-col space-y-4 ${
        $disabled ? `cursor-not-allowed` : ``
      }`}
    >
      <p className={$disabled ? `text-slate-500` : ``}>{label}</p>
      <div
        className={`flex rounded border items-center ${
          $disabled ? `cursor-not-allowed` : ``
        }`}
      >
        <input
          name={name}
          autoComplete="off"
          disabled={$disabled}
          className={`w-full focus:ring-transparent placeholder-slate-400 appearance-none py-3 pl-10 pr-4 hover:bg-slate-200 ${
            $disabled ? `cursor-not-allowed text-slate-500` : ``
          }`}
          type={iType}
          placeholder={placeholder}
          value={$value}
          onClick={(e) => {
            onClick(e);
          }}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
        {showIcon && (
          <button
            disabled={$disabled}
            className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 transition duration-150 rounded-full ml-3 mr-2 ${
              $disabled ? `cursor-not-allowed` : ``
            }`}
            aria-controls="search-modal"
            onClick={(e) => {
              onClick(e);
            }}
          >
            <span className="sr-only">Search</span>
            <svg
              className="w-4 h-4"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="fill-current text-slate-500"
                d="M7 14c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7zM7 2C4.243 2 2 4.243 2 7s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5z"
              />
              <path
                className="fill-current text-slate-400"
                d="M15.707 14.293L13.314 11.9a8.019 8.019 0 01-1.414 1.414l2.393 2.393a.997.997 0 001.414 0 .999.999 0 000-1.414z"
              />
            </svg>
          </button>
        )}
      </div>
    </div>
  );
};

export default Input;
