import * as React from "react";
import { hot } from "react-hot-loader";
import HomeRoute from "../pages/HomeRoute";
import "./../assets/scss/App.css";
import "!style-loader!css-loader!react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

class App extends React.Component<Record<string, unknown>, undefined> {
  public render() {
    return (
      <div className="app">
        <HomeRoute />
        <ToastContainer />
      </div>
    );
  }
}

declare let module: Record<string, unknown>;

export default hot(module)(App);
