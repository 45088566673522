import React from "react";

export type DropDownProps = {
  initialValue?: string;
  onSelectOption?;
  options: string[];
  initialMessage?: string;
  disabled?: boolean;
};

const Dropdown = ({
  initialValue,
  onSelectOption,
  options,
  initialMessage,
  disabled = false,
}: DropDownProps) => {
  const [defaultValue, setDefaultValue] = React.useState<string>("");
  const [showOptions, setShowOptions] = React.useState<boolean>(false);
  const [disable, setDisable] = React.useState<boolean>(disabled);

  React.useEffect(() => {
    setDisable(disabled);
    setDefaultValue(
      initialValue
        ? initialValue
        : initialMessage
        ? initialMessage
        : "Select the options",
    );
    setShowOptions(false);
  }, [disabled]);

  const onOptionClick = (key, value) => {
    setDefaultValue(value);
    setShowOptions(false);
    onSelectOption(key, value);
  };
  return (
    <div
      onMouseEnter={() => {
        !disable && setShowOptions(true);
      }}
      onMouseLeave={() => {
        !disable && setShowOptions(false);
      }}
    >
      <button
        id="dropdownButton"
        data-dropdown-toggle="dropdown"
        disabled={disable}
        className={`${disable ? `text-dark` : `text-white`}
                ${disable ? `bg-slate-100` : `bg-blue-700`}
                ${disable ? `cursor-not-allowed` : ``}
                hover: ${disable ? `bg-slate-100` : `bg-blue-800`}
                focus:ring-4 
                focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center 
                dark: ${disable ? `bg-slate-100` : `bg-blue-600`} 
                dark:hover: ${disable ? `bg-slate-100` : `bg-blue-700`} 
                dark:focus: ${disable ? `ring-slate-100` : `ring-blue-800`}`}
        type="button"
        onClick={() => {
          setShowOptions(true);
        }}
        // onMouseEnter={() => {
        //     setShowOptions(true);
        // }}
        // onMouseLeave={() => {
        //     setShowOptions(false);
        // }}
      >
        {defaultValue}
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          ></path>
        </svg>
      </button>

      <div
        id="dropdown"
        className={`${
          showOptions ? "" : "hidden"
        } border absolute border-stale z-10 w-44 text-base list-none bg-white rounded divide-y divide-gray-100 shadow dark:bg-gray-700`}
      >
        <ul className="py-1" aria-labelledby="dropdownButton">
          {Object.entries(options).map(([key, value], index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  onOptionClick(key, value);
                }}
              >
                <p className="block py-2 px-4 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">
                  {value}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Dropdown;
