import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyA4YCmTCWVTDUiCViLephUNYhuT2eKyyuo",
  authDomain: "acuhomes.firebaseapp.com",
  projectId: "acuhomes",
  storageBucket: "acuhomes.appspot.com",
  messagingSenderId: "630457737550",
  appId: "1:630457737550:web:ab509de3834f16e88d3e1f",
  measurementId: "G-FFQRMSYH6Z",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

/**
 *
 // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAsbw5viRRnERZpbW-uRR_N4jjJot-Bb8E",
  authDomain: "naveen-test-xl.firebaseapp.com",
  databaseURL: "https://naveen-test-xl-default-rtdb.firebaseio.com",
  projectId: "naveen-test-xl",
  storageBucket: "naveen-test-xl.appspot.com",
  messagingSenderId: "412457422830",
  appId: "1:412457422830:web:7680bc68b20fc2560588ff",
  measurementId: "G-7TD54YT670"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 */
