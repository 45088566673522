import React from "react";
import Header from "../component/ui/Header";
import MainArea from "../component/ui/MainArea";
const reactLogo = require("../assets/img/acu.svg");

const Directory = () => {
  return (
    <div>
      {/* <Header /> */}
      <MainArea />
      {/* <img
        className="mb-3 w-24 h-24 rounded-full shadow-lg"
        src={reactLogo.default}
        alt="Bonnie image"
      /> */}
    </div>
  );
};

export default Directory;
