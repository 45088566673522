import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { Healer } from "../model/healer";
import { db } from "./firebase-config";
import axios from "axios";

import { toast } from "react-toastify";

const tokenConfig = (token) => {
  return {
    headers: { Authorization: `Bearer ${token}` },
  };
};

const sucessToast = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const errorToast = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const getStates = async () => {
  const q = query(collection(db, "states"), orderBy("name"));
  const querySnapshot = await getDocs(q);
  const statesList = {};
  querySnapshot.forEach((doc) => {
    statesList[doc.id] = doc.data().name;
  });
  return Promise.resolve(statesList);
};

const getDistricts = async (district: string, key: string) => {
  const q = query(
    collection(db, "districts"),
    where("state", "==", district),
    orderBy("name", "asc"),
  );
  const querySnapshot = await getDocs(q);
  const districtList = {};
  querySnapshot.forEach((doc) => {
    districtList[doc.id] = doc.data().name;
  });

  return Promise.resolve(districtList);
};

const getCities = async (district: string, key: string) => {
  const q = query(
    collection(db, "cities"),
    where("district", "==", district),
    orderBy("name", "asc"),
  );
  const querySnapshot = await getDocs(q);
  const citiesList = {};
  querySnapshot.forEach((doc) => {
    citiesList[doc.id] = doc.data().name;
  });

  return Promise.resolve(citiesList);
};

const getHealers = async (city: string, district: string, state: string) => {
  const q = query(
    collection(db, "healers"),
    where("state", "==", state),
    where("district", "==", district),
    where("city", "==", city),
    orderBy("name", "asc"),
  );
  const querySnapshot = await getDocs(q);
  const healersList = {};
  querySnapshot.forEach((doc) => {
    healersList[doc.id] = {
      ...{ id: doc.id },
      ...doc.data(),
    } as unknown as Healer;
  });

  return Promise.resolve(healersList);
};

const downloadTemplate = async (token) => {
  axios
    .get(
      "https://5o87vdyb2l.execute-api.ap-south-1.amazonaws.com/api/Data/s3",
      tokenConfig(token),
    )
    .then((resp) => {
      const url = window.URL.createObjectURL(new Blob([resp.data.url]));
      const link = document.createElement("a");
      link.href = resp.data.url;
      link.setAttribute("download", `template`);
      document.body.appendChild(link);
      link.click();
      toast.success("Download started", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status == 401) {
          errorToast("Session expired!!! Refresh the page and login again");
        }
      }
      errorToast("Failed to download");
    });
};

const uploadTemplate = (fileName, file, token) => {
  const formData = new FormData();
  formData.append("formFile", file);
  formData.append("fileName", fileName);
  return axios
    .post(
      `https://5o87vdyb2l.execute-api.ap-south-1.amazonaws.com/api/Data/s3`,
      formData,
      tokenConfig(token),
    )
    .then((response) => {
      return "Sucess";
    })
    .catch((e) => {
      if (e.response) {
        if (e.response.status == 401) {
          errorToast("Session expired!!! Refresh the page and login again");
        }
      }
    })
    .finally(() => {
      sucessToast("Job started");
      return true;
    });
};

const getToken = (_email, _password) => {
  return axios
    .post(`https://5o87vdyb2l.execute-api.ap-south-1.amazonaws.com/v1/user`, {
      email: _email,
      password: _password,
      returnSecureToken: true,
    })
    .then((response) => {
      sucessToast("Logged in sucessfully");
      return response.data.token;
    })
    .catch((e) => {
      errorToast("Invalid Email or Password");
      return null;
    });
};

const getLogs = (token) => {
  return axios
    .get(
      `https://5o87vdyb2l.execute-api.ap-south-1.amazonaws.com/v1/job-detail`,
      tokenConfig(token),
    )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      if (e.response.status == 401) {
        errorToast("Session expired!!! Refresh the page and login again");
      } else {
        errorToast("Invalid Email or Password");
      }
      return null;
    });
};

const DirectoryService = {
  getStates,
  getDistricts,
  getCities,
  getHealers,
  downloadTemplate,
  uploadTemplate,
  getToken,
  getLogs,
};
export default DirectoryService;
